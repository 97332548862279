import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactPerson from "../components/contactPerson";
import Testimonial from "../components/testimonial";
import { PartnersGrid } from "../components/partnersGrid";
import { ServicesTeaser } from "../components/servicesTeaser";

import doodle_path from "../assets/images/doodle-path.svg";
import doodle_directions from "../assets/images/doodle-directions.svg";
import doodle_growIdeas from "../assets/images/doodle-grow-ideas.svg";
import eppendorf from "../assets/images/logos/svg/eppendorf.svg";
import dak_gesundheit from "../assets/images/logos/svg/dak-gesundheit.svg";
import cosmos from "../assets/images/logos/svg/cosmos.svg";
import tk from "../assets/images/logos/svg/tk.svg";
import swp from "../assets/images/logos/svg/swp.svg";
import generali from "../assets/images/logos/svg/generali.svg";
import hansemerkur_green from "../assets/images/logos/svg/hansemerkur_green.svg";
import bgw_green from "../assets/images/logos/svg/bgw_green.svg";
import haspa_green from "../assets/images/logos/svg/haspa_green.svg";

const Leistungen = () => {
  const images = useStaticQuery(graphql`
    {
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const Testimonials = [
    {
      image: hansemerkur_green,
      name: "Katrin Menzel",
      position: "Senior Online Marketing Manager bei der HanseMerkur",
      text: "Der Versicherungsfinder, umgesetzt mit Formcentric, ist ein überzeugendes Tool, das für unsere Kunden einen bequemen Weg zu genau den Informationen bietet, die sie suchen. Und die Möglichkeit, das Angebot selbstständig dynamisch zu erweitern, stärkt die Präsenz der HanseMerkur im Netz heute und für die Zukunft.",
      linkTo: "hansemerkur",
    },
    {
      image: bgw_green,
      name: "Oliver Willhöft",
      position: "Bereichsleiter Softwaretechnologien bei der BGW",
      text: (
        <Fragment>
          Der Auftritt ist mit dem neuen und modernen Design gelungen. Auch der
          neue technologische Ansatz und Automatisierungs&shy;möglichkeiten
          werden uns in der Zukunft mit Sicherheit helfen, schneller und
          flexibler agieren zu können.
        </Fragment>
      ),
      linkTo: "bgw",
    },
    {
      image: haspa_green,
      name: "Peter Hußmann",
      position:
        "Bereich Informationstechnologie und Organisation bei der Haspa",
      text: "Ob Design, neuen Funktionen oder der Pflege des Content-Management-Systems – mit Monday Consulting haben wir einen vertrauenswürdigen Partner auf Augenhöhe gefunden, der unsere Anforderungen optimal umsetzt und durch eigene Ideen optimale Lösungen ermöglicht.",
      linkTo: "haspa",
    },
  ];

  return (
    <Layout lang="de" langPath="nav.services.link">
      <Seo
        title="Unsere Leistungen – Maßgeschneiderte Lösungen für Ihren Webauftritt"
        description="Mit Fokus auf Barrierefreiheit und Personalisierung optimieren wir Ihre digitale Präsenz und machen Ihre User Experience einzigartig und zukunftsfähig."
        keywords="User Experience, Implementierungspartner, CMS-Implementierung, Content-Management-System, CMS, CoreMedia, Firstspirit, Crownpeak, Consulting, Softwareentwicklung, Netlify, Dynamic Yield, Formcentric, IAAP, Barrierefreiheit, Personalisierung"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>

      <div className="container mt-4 mb-4 mb-md-4">
        <div className="row">
          <div className="col-12">
            <h1 className="h5 text-color-monday-green">Leistungen</h1>
            <h2 className="text-size-2 text-size-md-1">
              Individuelle Lösungen für individuelle Herausforderungen
            </h2>
            <p>
              Mit uns erhalten Sie erstklassige digitale Lösungen und eine
              partnerschaftliche Zusammenarbeit, die Ihren Anforderungen gerecht
              wird.
              <br />
              Mit unserem erfahrenen Team und modernsten Technologien sichern
              wir nicht nur herausragende Ergebnisse, sondern auch eine schnelle
              Anpassungsfähigkeit an neue Herausforderungen – für Ihren
              langfristigen und nachhaltigen Erfolg.
            </p>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Spezialisierung für Ihren Erfolg"
        teaser={
          "Mit unserer langjährigen Expertise und ausgewählten Partnerschaften sind wir bestens auf Ihre Anforderungen vorbereitet."
        }
      />

      <div className="container">
        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-dots">
            <img
              className="w-100"
              src={doodle_path}
              alt="Digitale Barrieren überwinden"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Inklusive digitale Welt
            </h2>
            <h3>Digitale Barrieren überwinden</h3>
            <p>
              Wir fördern eine inklusive digitale Welt, um sie für alle
              Menschen, unabhängig von individuellen Einschränkungen, nutzbar zu
              machen. Unser Team unterstützt Sie, auch Ihren Online-Auftritt
              inklusiv zu gestalten und zukünftige Standards frühzeitig zu
              erfüllen. Seien Sie heute schon zukunftsfähig und vorbereitet für
              den EAA (European Accessibility Act).
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-circle">
            <img
              className="w-100"
              src={doodle_directions}
              alt="Personalisierte Erlebnisse im Web"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Zielgerichtete Ausspielung von Inhalten
            </h2>
            <h3>Personalisierte Erlebnisse im Web</h3>
            <p>
              Eine personalisierte User Experience steigert Umsätze, minimiert
              Kosten und fördert Kundenloyalität durch maßgeschneiderte
              Erlebnisse, die auf individuellen Bedürfnissen basieren – die
              ideale Strategie für effektive Kundenansprache und langfristige
              Beziehungen.
            </p>
          </div>
        </div>

        <div className="row mb-5 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-vertigo">
            <img
              className="w-100"
              src={doodle_growIdeas}
              alt="Digitalisierung von Prozessen"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Die digitale Transformation vorantreiben
            </h2>
            <h3>Digitalisierung von Prozessen</h3>
            <p>
              Analoge Prozesse im Handumdrehen digitalisieren: Beschleunigen Sie
              Geschäftsprozesse durch benutzerfreundliche Online-Formulare,
              automatisieren Sie Services und generieren Sie Leads – sicher,
              DSGVO-konform und zertifiziert nach ISO 27001. Nutzen Sie die
              Vorteile des Formularmanagers Formcentric für effizientes und
              sicheres Arbeiten.
            </p>
          </div>
        </div>
      </div>

      <ServicesTeaser
        title="Entdecken Sie unsere Möglichkeiten"
        text={
          "Mit unserer Expertise in Strategie und Konzeption gestalten wir gemeinsam optimale Lösungen, die heute die Standards von morgen erfüllen.\nVon der Auswahl des idealen Content-Management-Systems über einzigartiges Design und Kreation bis hin zur Optimierung von Frontend, Backend und Infrastruktur – wir bieten Ihnen alles aus einer Hand.\n\nWir begleiten Sie durch den gesamten Prozess und stehen auch nach Projektabschluss mit erstklassigem Customer Care zur Seite."
        }
        ctaLink="/leistungen/leistungsportfolio"
        ctaText="Unsere Leistungen im Überblick"
      />

      <div
        className="content-container vertical-spacing bg-monday-green bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="testimonials"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h2 className="mb-3">
                Diese Unternehmen setzen auf Monday Consulting
              </h2>
              <p>
                Unternehmen aus diversen Branchen verbessern mit unseren
                individuellen und zukunftssicheren Lösungen den Dialog mit den
                Kunden sowie interne Prozesse und sichern so ihren Erfolg am
                Markt. Dabei gleicht kein Case dem anderen und das macht uns
                besonders stolz.
              </p>
              <Link className="btn" to="/referenzen">
                Unsere Referenzen
              </Link>
            </div>
          </div>
          <div className="row mt-4 mb-2 align-items-center justify-content-center">
            <div className="p-2 flex-grow-0 mb-2">
              <img src={eppendorf} alt="Eppendorf Gruppe" height="30px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={dak_gesundheit} alt="DAK-Gesundheit" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={cosmos} alt="CosmosDirekt" height="70px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={tk} alt="Techniker Krankenkasse" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={swp} alt="Stadtwerke Potsdam" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={generali} alt="Generali" height="50px" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="testimonials"
        className="container vertical-offset mb-5 mb-md-6 px-0 px-md-3"
        data-offset="50%"
      >
        <div className="swiper-container reveal">
          <div className="swiper-wrapper">
            {Testimonials.map((item, i) => (
              <Testimonial key={i} {...item} />
            ))}
          </div>
          <div className="swiper-pagination d-xl-none"></div>
        </div>
      </div>

      <ContactPerson
        headline="Dürfen wir Ihnen behilflich sein?"
        text="Überzeugen Sie sich noch heute von unseren Leistungen und nehmen Sie direkt Kontakt mit uns auf. Wir stehen Ihnen mit Rat und Tat zur Seite."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Geschäftsführer"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Leistungen;
